import { validateAddDomainForm } from '../utils/validators';
import { renderField } from './form-elements';
import { Field, reduxForm } from 'redux-form';
import React, { PureComponent } from 'react';
import { noop } from '../utils/utils';
import PropTypes from 'prop-types';
import { compose } from 'redux';

class AddDomainForm extends PureComponent {
  render() {
    const { handleSubmit, showClose, onCloseClick, onSubmit, disabled, loading } = this.props;
    return (
      <div className="add-domain add-domain-form">
        <div className="form-group mb-0">
          <label htmlFor="name">Domain name</label>
          <div className="input-field">
            <Field
              className="form-control form-control-lg"
              component={ renderField }
              readOnly={ disabled }
              placeholder=""
              name="name"
              type="text"
              id="domain_name"
            />
          </div>
        </div>
        <div className="form-group mb-0">
          <label htmlFor="email">email</label>
          <div className="input-field">
            <Field
              className="form-control form-control-lg"
              component={ renderField }
              readOnly={ disabled }
              placeholder=""
              name="email"
              type="email"
              id="email"
            />
          </div>
        </div>
        <div className="switch-block-right">
          <button className={`btn btn-md mb-0 btn-blue no-shadow ${ loading ? 'loading' : '' }`} type="button" onClick={ handleSubmit(onSubmit)}>
            <i className="icons add" />Add Domain
          </button>
          { showClose &&
            <button className="remove-file close-add-domain" type="button" onClick={ onCloseClick }>
              <i className="icons close-regular" />
            </button>
          }
        </div>
      </div>
    );
  }
}

AddDomainForm.propTypes = {
  onCloseClick: PropTypes.func,
  showClose: PropTypes.bool,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

AddDomainForm.defaultProps = {
  onCloseClick: noop,
  showClose: false,
  disabled: false,
  onSubmit: noop
};

export default compose(
  reduxForm({
    validate: validateAddDomainForm,
    form: 'add-domain-form'
  }),
)(AddDomainForm);
