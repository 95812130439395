import { Cookies } from 'react-cookie';
import {
  noop,
  getApiUrl,
  getLandingHost,
  getDomainName,
} from '../utils/utils';
import * as AccountActions from '../actions/account';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Auth {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Set hedare access token
   * @param token
   */
  // jwt(token) {

  // }

  /**
   * Confirm user signup with code
   * @param {*} param0
   */
  confirmSignUp({ resolve = () => {}, reject = () => {}, data = {} }) {
    return (
      dispatch,
      getState,
      { axios },
    ) => {
      return axios
        .post(`${API_URL}/users/sign-up/confirm/`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

  /**
   * Validate reset password token
   * @returns {function(*=, *, {axios: *})}
   */
  validateResetPasswordToken({
    resolve = noop,
    reject = noop,
    data = {},
  }) {
    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.reset_password_token_validation_request({
        reset_password_token_valid_loaded: false,
        reset_password_token_valid_loading: true,
        reset_password_token_valid: false,
      }));
      return axios
        .post(`${API_URL}/reset-password/token-check/`, data)
        .then((response) => {
          dispatch(AccountActions.reset_password_token_validation_success({
            reset_password_token_valid: response.data.is_valid,
            reset_password_token_valid_loading: false,
            reset_password_token_valid_loaded: true,
          }));
          resolve(response, dispatch);
        })
        .catch((error) => {
          dispatch(AccountActions.reset_password_token_validation_failure({
            reset_password_token_valid_loading: false,
            reset_password_token_valid_loaded: true,
            reset_password_token_valid: false,
          }));
          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

  /**
   * Validate token
   * @returns {function(*=, *, {axios: *})}
   */
  validateToken({
    resolve = noop,
    reject = noop,
    data = {},
  }) {
    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.reset_password_token_validation_request({
        reset_password_token_valid_loaded: false,
        reset_password_token_valid_loading: true,
        reset_password_token_valid: false,
      }));
      return axios
        .post(`${API_URL}/representative/token-check/`, data)
        .then((response) => {
          dispatch(AccountActions.reset_password_token_validation_success({
            reset_password_token_valid: response.data.valid,
            reset_password_token_valid_loading: false,
            reset_password_token_valid_loaded: true,
          }));
          resolve(response.data, dispatch);
        })
        .catch((error) => {
          dispatch(AccountActions.reset_password_token_validation_failure({
            reset_password_token_valid_loading: false,
            reset_password_token_valid_loaded: true,
            reset_password_token_valid: false,
          }));
          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

  /**
   * Logout action
   * @returns {function(*, *, {axios: *})}
   */
  logout() {
    return (dispatch) => {
      const cookies = new Cookies();
      const domainName = getDomainName();
      cookies.remove(`auth_${getLandingHost()}`, {
        domain: `${domainName}`,
        path: '/',
      });
      // cookies.remove('token', { path: '/' });
      localStorage.removeItem('token');
      cookies.remove('refresh', { path: '/' });
      cookies.remove('closed_draft_orders', { path: '/' });
      dispatch(AccountActions.logout_request());
    };
  }

  /**
   * Login user
   * @param resolve
   * @param reject
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  login({ resolve = () => {}, reject = () => {}, data = {} }) {
    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.login_request(data));
      return axios
        .post(`${API_URL}/api-auth/`, data)
        .then((outerResponse) => {
            const { access } = outerResponse.data;

            dispatch(AccountActions.request({ authentication_loading: true }));
            resolve(outerResponse.data, dispatch);
            // axios
            // .get(`${API_URL}/users/profile/`)
            // .then(({ data }) => {
            //   dispatch(
            //     AccountActions.success({
            //       isAuthenticated: true,
            //       user: {
            //         companies: data.companies,
            //         ...data.result,
            //         info: {
            //           ...data.result.info[0],
            //         },
            //       },
            //     }),
            //   );
            //   resolve(outerResponse.data, dispatch);
            // })
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

  /**
   * Reset password
   * @param resolve
   * @param reject
   * @param token
   * @param data
   * @returns {function(*=, *, {axios: *})}
   */
  resetPassword({
    resolve = () => {},
    reject = () => {},
    token = null,
    data = {},
  }) {
    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.forgot_password_request({ loading_forgot_password: true }));
      return axios
        .post(`${API_URL}/reset-password-confirm/${token}`, data)
        .then((response) => {
          resolve(response, dispatch);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

  /**
   * Forgot password
   * @param resolve
   * @param reject
   * @param data
   * @param headers
   * @returns {function(*=, *, {axios: *})}
   */
  forgotPassword({
    resolve = () => {},
    reject = () => {},
    data = {},
    headers = {},
  }) {
    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.forgot_password_request({ loading_forgot_password: true }));
      return axios
        .delete(`${API_URL}/reset-password/`, { headers, data })
        .then((response) => {
          dispatch(AccountActions.forgot_password_success({ loading_forgot_password: false }));
          resolve(response, dispatch);
        })
        .catch((error) => {
          dispatch(AccountActions.forgot_password_failure({ loading_forgot_password: false }));
          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

  /**
   * Load user data
   * @param data
   * @returns {function(*)}
   */
  loadUserData({ data = {} }) {
    return (dispatch) => {
      dispatch(AccountActions.data_loaded(data));
    };
  }

  /**
   * Register user
   * @param resolve
   * @param reject
   * @param data
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  register({
    resolve = () => {},
    reject = () => {},
    data = {},
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.register_user_request(data));
      return axios
        .post(`${API_URL}/users/sign-up/`, data, autorization)
        .then((response) => {
          dispatch(AccountActions.register_user_success(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(AccountActions.register_user_failure(error));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Test if user it's auth
   * @param resolve
   * @param reject
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  getProfile({
    resolve = noop,
    reject = noop,
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.request({ authentication_loading: true }));
      return axios
        .get(`${API_URL}/users/profile/`, autorization)
        .then(({ data }) => {
          dispatch(
            AccountActions.success({
              isAuthenticated: true,
              user: {
                companies: data.companies,
                ...data.result,
                info: {
                  ...data.result.info[0],
                },
              },
            }),
          );
          resolve(data);
        })
        .catch((error) => {
          if (error.status === 401) {
            dispatch(
              AccountActions.failure({
                isAuthenticated: false,
                user: null,
              }),
            );
          } else {
            dispatch(AccountActions.failure({}));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Test if user it's auth
   * @param resolve
   * @param reject
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  isAuth({
    resolve = noop,
    reject = noop,
  }) {
    // HOTFIX
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

    return (dispatch, { axios }) => {
      dispatch(AccountActions.request({ authentication_loading: true }));
      axios
        .get(`${API_URL}/users/profile/`)
        .then(({ data }) => {
          dispatch(
            AccountActions.success({
              isAuthenticated: true,
              user: {
                companies: data.companies,
                ...data.result,
                info: {
                  ...data.result.info[0],
                },
              },
            }),
          );
          resolve(data);
        }).catch((error1) => {
          reject(error1);
        });
    };
  }
}

export default new Auth();
